import React from 'react';
import { Card, CardTheme } from 'components/Card';
import { useReportDataValue } from 'components/ReportDataContainer';
import { BlockIdentifier } from 'components/SchemaRenderer/BlockIdentifier.enum';

import { BaseBlock } from '../BaseBlock';

import styles from './GenericSection.module.scss';

interface Metadata {
  theme?: CardTheme;
  hideDivider?: boolean;
}

export class GenericSection extends BaseBlock<Metadata, boolean> {
  identifier = BlockIdentifier.GenericSection;

  Component: React.FC<Metadata> = ({
    children,
    theme = CardTheme.Transparent,
    hideDivider = false
  }) => {
    const isHidden = useReportDataValue(this.accessor);

    if (isHidden) {
      return null;
    }

    return (
      <div className={styles['generic-section']}>
        {!hideDivider && (
          <div data-testid='divider' className={styles.divider} />
        )}
        <Card theme={theme} className={styles['inner']}>
          {children}
        </Card>
      </div>
    );
  };
}
