import React, { FC } from 'react';
import cn from 'classnames';
import {
  EventsTreeSubitem,
  EventsTreeSubitemTheme
} from 'components/ReportDataContainer/ReportDataContainer';

import styles from './ShoppingCart.module.scss';

interface Props {
  className?: string;
  nodes: EventsTreeSubitem[];
}

export const ShoppingCart: FC<Props> = ({ nodes, className }) => (
  <div className={cn(className, styles.container)}>
    {nodes.map(({ text, theme = EventsTreeSubitemTheme.Primary }, index) => (
      <span
        className={styles[theme]}
        data-testid='shoppingCart-node'
        key={index}
      >
        {text}
      </span>
    ))}
  </div>
);
