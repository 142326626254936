import React, { useCallback, useRef } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import cn from 'classnames';
import { Button, ButtonTheme } from 'components/Button';
import { Icon, IconType } from 'components/Icon';

import { SystemMessageTheme } from './SystemMessage.enum';

import styles from './SystemMessage.module.scss';

interface Props {
  visible: boolean;
  theme: SystemMessageTheme;
  title: string;
  className?: string;
  onClose?: () => void;
}

const icons = Object.freeze({
  [SystemMessageTheme.Success]: IconType.CheckCircle,
  [SystemMessageTheme.Error]: IconType.ErrorCircle,
  [SystemMessageTheme.Warning]: IconType.Warning,
  [SystemMessageTheme.Information]: IconType.Info
});

const slideClassNames = {
  appear: styles['slide-appear'],
  appearActive: styles['slide-appear-active'],
  enter: styles['slide-enter'],
  enterActive: styles['slide-enter-active'],
  exit: styles['slide-exit'],
  exitActive: styles['slide-exit-active']
};

export const SystemMessage: React.FC<Props> = ({
  visible,
  theme,
  className,
  title,
  onClose
}) => {
  const nodeRef = useRef<HTMLDivElement>(null);
  const nodeWrapRef = useRef<HTMLDivElement>(null);
  const handleTransitionEnd = useCallback(
    (done: () => void) =>
      nodeRef.current?.addEventListener('transitionend', done, false),
    []
  );
  const handleWrapTransitionEnd = useCallback(
    (done: () => void) =>
      nodeRef.current?.addEventListener('transitionend', done, false),
    []
  );

  return (
    <div className={cn(styles['system-msg'], className)}>
      <CSSTransition
        in={visible}
        classNames={slideClassNames}
        unmountOnExit
        timeout={200}
        nodeRef={nodeWrapRef}
        addEndListener={handleWrapTransitionEnd}
      >
        <div ref={nodeWrapRef}>
          <SwitchTransition>
            <CSSTransition
              appear={true}
              key={title}
              addEndListener={handleTransitionEnd}
              classNames={slideClassNames}
              nodeRef={nodeRef}
            >
              <div
                ref={nodeRef}
                className={cn(styles.container, styles[theme])}
                data-testid='system-msg'
              >
                <Icon iconType={icons[theme]} className={styles.icon} />
                <p className={styles.title}>{title}</p>
                {onClose && (
                  <Button
                    className={styles['btn-close']}
                    onClick={onClose}
                    icon={IconType.Close}
                    theme={ButtonTheme.SecondaryTransparent}
                    iconClassName={styles['close-icon']}
                  />
                )}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </CSSTransition>
    </div>
  );
};
