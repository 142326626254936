import { ReactComponent as AddComment } from '../../assets/svg/add-comment.svg';
import { ReactComponent as Amplitude } from '../../assets/svg/amplitude.svg';
import { ReactComponent as Arrow } from '../../assets/svg/arrow.svg';
import { ReactComponent as Bell } from '../../assets/svg/bell.svg';
import { ReactComponent as Book } from '../../assets/svg/book.svg';
import { ReactComponent as Broken } from '../../assets/svg/broken.svg';
import { ReactComponent as CaretDown } from '../../assets/svg/caret-down.svg';
import { ReactComponent as CaretRight } from '../../assets/svg/caret-right.svg';
import { ReactComponent as Check } from '../../assets/svg/check.svg';
import { ReactComponent as CheckCircle } from '../../assets/svg/check-circle.svg';
import { ReactComponent as ChevronDown } from '../../assets/svg/chevron-down.svg';
import { ReactComponent as ChevronRight } from '../../assets/svg/chevron-right.svg';
import { ReactComponent as Classify } from '../../assets/svg/classify.svg';
import { ReactComponent as Close } from '../../assets/svg/close.svg';
import { ReactComponent as Cog } from '../../assets/svg/cog.svg';
import { ReactComponent as Dots } from '../../assets/svg/dots.svg';
import { ReactComponent as Duration } from '../../assets/svg/duration.svg';
import { ReactComponent as Edit } from '../../assets/svg/edit.svg';
import { ReactComponent as Empty } from '../../assets/svg/empty.svg';
import { ReactComponent as Error } from '../../assets/svg/error.svg';
import { ReactComponent as ErrorCircle } from '../../assets/svg/error-circle.svg';
import { ReactComponent as EventCount } from '../../assets/svg/event-count.svg';
import { ReactComponent as Eye } from '../../assets/svg/eye.svg';
import { ReactComponent as File } from '../../assets/svg/file.svg';
import { ReactComponent as Filter } from '../../assets/svg/filter.svg';
import { ReactComponent as FilterActive } from '../../assets/svg/filter-active.svg';
import { ReactComponent as Frequency } from '../../assets/svg/frequency.svg';
import { ReactComponent as Hidden } from '../../assets/svg/hidden.svg';
import { ReactComponent as History } from '../../assets/svg/history.svg';
import { ReactComponent as Holberg } from '../../assets/svg/holberg.svg';
import { ReactComponent as Info } from '../../assets/svg/info.svg';
import { ReactComponent as Interval } from '../../assets/svg/interval.svg';
import { ReactComponent as Link } from '../../assets/svg/link.svg';
import { ReactComponent as Maximize } from '../../assets/svg/maximize.svg';
import { ReactComponent as Menu } from '../../assets/svg/menu.svg';
import { ReactComponent as Minimize } from '../../assets/svg/minimize.svg';
import { ReactComponent as Note } from '../../assets/svg/note.svg';
import { ReactComponent as Pin } from '../../assets/svg/pin.svg';
import { ReactComponent as Plus } from '../../assets/svg/plus.svg';
import { ReactComponent as Preloader } from '../../assets/svg/preloader.svg';
import { ReactComponent as Profile } from '../../assets/svg/profile.svg';
import { ReactComponent as Properties } from '../../assets/svg/properties.svg';
import { ReactComponent as QuestionCircle } from '../../assets/svg/question-circle.svg';
import { ReactComponent as Recordings } from '../../assets/svg/recordings.svg';
import { ReactComponent as Required } from '../../assets/svg/required.svg';
import { ReactComponent as Saved } from '../../assets/svg/saved.svg';
import { ReactComponent as Search } from '../../assets/svg/search.svg';
import { ReactComponent as Simultaneous } from '../../assets/svg/simultaneous.svg';
import { ReactComponent as SkipArrow } from '../../assets/svg/skip-arrow.svg';
import { ReactComponent as Subdirectory } from '../../assets/svg/subdirectory.svg';
import { ReactComponent as Target } from '../../assets/svg/target.svg';
import { ReactComponent as Time } from '../../assets/svg/time.svg';
import { ReactComponent as Trash } from '../../assets/svg/trash.svg';
import { ReactComponent as UndoSimultaneous } from '../../assets/svg/undo-simultaneous.svg';
import { ReactComponent as Warning } from '../../assets/svg/warning.svg';
import { ReactComponent as WarningFilled } from '../../assets/svg/warning-filled.svg';

export enum IconType {
  AddComment = 'add-comment',
  Empty = 'empty',
  Error = 'error',
  Preloader = 'preloader',
  Saved = 'saved',
  Eye = 'eye',
  Filter = 'filter',
  FilterActive = 'filter-active',
  Bell = 'bell',
  Book = 'book',
  Edit = 'edit',
  File = 'file',
  CaretRight = 'caret-right',
  CaretDown = 'caret-down',
  ChevronDown = 'chevron-down',
  ChevronRight = 'chevron-right',
  Close = 'close',
  Check = 'check',
  Cog = 'cog',
  Hidden = 'hidden',
  History = 'history',
  Info = 'info',
  Dots = 'dots',
  Pin = 'pin',
  Plus = 'plus',
  Menu = 'menu',
  Maximize = 'maximize',
  Minimize = 'minimize',
  Profile = 'profile',
  Properties = 'properties',
  Search = 'search',
  Simultaneous = 'simultaneous',
  UndoSimultaneous = 'undo-simultaneous',
  SkipArrow = 'skip-arrow',
  Subdirectory = 'subdirectory',
  Arrow = 'arrow',
  Target = 'target',
  Classify = 'classify',
  Link = 'link',
  Warning = 'warning',
  Broken = 'broken',
  Required = 'required',
  Interval = 'interval',
  Duration = 'duration',
  Time = 'time',
  Note = 'note',
  EventCount = 'event-count',
  Frequency = 'frequency',
  Amplitude = 'amplitude',
  Holberg = 'holberg',
  WarningFilled = 'warningFilled',
  ErrorCircle = 'error-circle',
  CheckCircle = 'check-circle',
  QuestionCircle = 'question-circle',
  Recordings = 'recordings',
  Trash = 'trash'
}

export const IconComponent = Object.freeze<
  Record<IconType, React.StatelessComponent<React.SVGAttributes<SVGElement>>>
>({
  [IconType.Empty]: Empty,
  [IconType.AddComment]: AddComment,
  [IconType.Eye]: Eye,
  [IconType.Error]: Error,
  [IconType.Preloader]: Preloader,
  [IconType.Saved]: Saved,
  [IconType.Filter]: Filter,
  [IconType.FilterActive]: FilterActive,
  [IconType.Bell]: Bell,
  [IconType.Book]: Book,
  [IconType.CaretRight]: CaretRight,
  [IconType.CaretDown]: CaretDown,
  [IconType.Check]: Check,
  [IconType.ChevronDown]: ChevronDown,
  [IconType.ChevronRight]: ChevronRight,
  [IconType.History]: History,
  [IconType.Close]: Close,
  [IconType.Cog]: Cog,
  [IconType.Dots]: Dots,
  [IconType.Edit]: Edit,
  [IconType.File]: File,
  [IconType.Hidden]: Hidden,
  [IconType.Info]: Info,
  [IconType.Pin]: Pin,
  [IconType.Plus]: Plus,
  [IconType.Properties]: Properties,
  [IconType.Menu]: Menu,
  [IconType.Profile]: Profile,
  [IconType.Search]: Search,
  [IconType.Simultaneous]: Simultaneous,
  [IconType.UndoSimultaneous]: UndoSimultaneous,
  [IconType.SkipArrow]: SkipArrow,
  [IconType.Subdirectory]: Subdirectory,
  [IconType.Arrow]: Arrow,
  [IconType.Target]: Target,
  [IconType.Classify]: Classify,
  [IconType.Required]: Required,
  [IconType.Link]: Link,
  [IconType.Warning]: Warning,
  [IconType.Broken]: Broken,
  [IconType.Interval]: Interval,
  [IconType.Duration]: Duration,
  [IconType.Time]: Time,
  [IconType.Note]: Note,
  [IconType.EventCount]: EventCount,
  [IconType.Frequency]: Frequency,
  [IconType.Amplitude]: Amplitude,
  [IconType.Holberg]: Holberg,
  [IconType.WarningFilled]: WarningFilled,
  [IconType.CheckCircle]: CheckCircle,
  [IconType.ErrorCircle]: ErrorCircle,
  [IconType.QuestionCircle]: QuestionCircle,
  [IconType.Maximize]: Maximize,
  [IconType.Minimize]: Minimize,
  [IconType.Recordings]: Recordings,
  [IconType.Trash]: Trash
});
