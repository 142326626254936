import React from 'react';
import cn from 'classnames';

import { CardTheme } from './Card.enum';

import styles from './Card.module.scss';

interface Props {
  className?: string;
  theme?: CardTheme;
  isDisabled?: boolean;
}

export const Card: React.FC<Props> = ({
  children,
  className,
  theme = CardTheme.Popup,
  isDisabled
}) => (
  <div
    data-testid='card'
    className={cn(
      styles.card,
      styles[theme],
      isDisabled && styles.disabled,
      className
    )}
  >
    {children}
  </div>
);
